import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import Screen from "./components/screen/screen";


class App extends Component {
  render() {
    return  <Screen />
  }
}

export default App;
